import { useParam } from "hooks/useParam";
import { useSchoolarKit } from "hooks/useSchoolarKit";
import { MSchoolKitRequest, MSchoolKitResponse } from "models/schoolKitModel";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import {
  PARAM_ERROR_USUARIO_NO_CORRESPONDE,
  PARAM_LINK_ERROR_USUARIO_NO_CORRESPONDE,
  PARAM_LINK_NO_KIT_ESCOLAR,
  PARAM_MENSAJE_ADJUDICACION_KIT_ESCOLAR,
  PARAM_MENSAJE_POSTULACION_KIT_ESCOLAR,
  PARAM_NO_KIT_ESCOLAR,
} from "utils/constants";
import manosComfenalco from "../../../assets/images/manosComfenalco.png";
import { Loading } from "components/Loading";
import { SchoolKitInformation } from "components/SchoolKitInformation";
import { Category } from "models/category";
import { IsCategoryAvailable } from "utils/CategoryAvailable";

interface SchoolKitProps {
  selectedCategory?: Category;
}

export const SchoolKit = ({ selectedCategory }: SchoolKitProps) => {
  const { isLogged, authData } = useSelector((state: RootState) => state.auth);
  const { getParamData } = useParam();
  const { getSchoolKitInfo } = useSchoolarKit();

  const [hasNoKitError, setHasNoKitError] = useState(false);
  const [noKitError, setNoKitError] = useState<string>();
  const [linkOnNoKitError, setLinkNoKitError] = useState<string>();

  const [hasNoUserError, setHasNoUserError] = useState(false);
  const [errorNoUser, setErrorNoUser] = useState<string>();
  const [linkOnNoUserError, setLinkNoUserError] = useState<string>();

  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [schoolKitData, setSchooLkitData] = useState<MSchoolKitResponse>();

  const [adjudicationMessage, setAdjudicationMessage] = useState<string>();
  const [postulationMessage, setPostulationMessage] = useState<string>();

  const isWithinRange = selectedCategory && IsCategoryAvailable(selectedCategory)


  useEffect(() => {
    const getSubsidy = async () => {
      setIsLoading(true);

      const noUserMatch = await getParamData(
        PARAM_ERROR_USUARIO_NO_CORRESPONDE
      );
      const linkNoUserMatch = await getParamData(
        PARAM_LINK_ERROR_USUARIO_NO_CORRESPONDE
      );
      const noSchoolKitParam = await getParamData(PARAM_NO_KIT_ESCOLAR);
      const linkNoSchoolKitParam = await getParamData(
        PARAM_LINK_NO_KIT_ESCOLAR
      );

      const postulation = await getParamData(
        PARAM_MENSAJE_POSTULACION_KIT_ESCOLAR
      );
      const adjudication = await getParamData(
        PARAM_MENSAJE_ADJUDICACION_KIT_ESCOLAR
      );

      setErrorNoUser(noUserMatch?.data?.valorParametro);
      setLinkNoUserError(linkNoUserMatch?.data?.valorParametro);
      setNoKitError(noSchoolKitParam?.data?.valorParametro);
      setLinkNoKitError(linkNoSchoolKitParam?.data?.valorParametro);

      setAdjudicationMessage(adjudication?.data?.valorParametro);
      setPostulationMessage(postulation?.data?.valorParametro);

      if (isLogged) {
        const schoolKitRequest: MSchoolKitRequest = {
          abreviatura: authData.afiliado.abreviatura!,
          identificacion: authData.afiliado.identificacion!,
        };
        //schoolKitRequest.identificacion = '94426371'

        const schoolKitResponse = await getSchoolKitInfo(schoolKitRequest);
        if (!schoolKitResponse) {
          setHasNoKitError(true);
        } else {
          setSchooLkitData(schoolKitResponse);
          const contributor = schoolKitResponse?.cotizante;

          if (
            (contributor?.identificacion == authData.afiliado.identificacion &&
              contributor?.idAbreviatura == authData.afiliado.idTiid)
          ) {
            if (
              (schoolKitResponse?.solicitud.estado == "GENERADO" ||
                schoolKitResponse?.solicitud.estado == "RECHAZADO" ||
                schoolKitResponse?.solicitud.estado == "ADJUDICADO") &&
              schoolKitResponse?.solicitud.mostrarAdjudicacionEcommerce == "S"
            ) {

              setCurrentStep(1);
              setHasNoKitError(false);
            } else {

              setCurrentStep(0);
            }
          } else {
            setHasNoUserError(true);
          }
        }
      }
      setIsLoading(false);
    };
    getSubsidy();

    return () => {
      setIsLoading(false);
      setNoKitError(undefined);
      setLinkNoKitError(undefined);
      setSchooLkitData(undefined);
    };
  }, []);
  return (
    <>
      {isLogged ? (
        <>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {!isWithinRange ?
                <div style={{ alignItems: "center" }}>
                  <SchoolKitInformation
                    // color={color}
                    currentStep={currentStep}
                    beneficiaries={schoolKitData?.beneficiarios.beneficiario}
                    schoolKitData={schoolKitData}
                    hasNoKitError={hasNoKitError}
                    noKitError={noKitError}
                    linkOnNoKitError={linkOnNoKitError}
                    hasNoUserError={hasNoUserError}
                    errorNoUser={errorNoUser}
                    linkOnNoUserError={linkOnNoUserError}
                    adjudicationMessage={adjudicationMessage}
                    postulationMessage={postulationMessage}
                  />
                </div> :
                <div className="flex flex-col w-full items-center pt-4">
                  <div className="flex flex-col w-2/3 items-center rounded-lg text-center bg-principal-350 p-2">
                    <img
                      src={manosComfenalco}
                      className="h-16 w-16"
                    />
                    <p className="text-principal-600 font-bold">
                      {selectedCategory?.mensajeInactividad && selectedCategory?.mensajeInactividad}
                    </p>
                  </div>
                </div>}
            </>
          )}
        </>
      ) : (
        <div className="flex flex-col w-full items-center pt-4">
          <div className="flex flex-col w-2/3 items-center rounded-lg text-center bg-principal-350 p-2">
            <img src={manosComfenalco} className="h-16 w-16" />
            <p className="text-principal-600 font-bold">
              No hay información para mostrar. Si deseas consultar tu kit
              escolar por favor inicia sesión.
            </p>
          </div>
        </div>
      )}
    </>
  );
};
