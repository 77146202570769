import { MPurchaseHistory, PurchaseHistoryResponse } from "models/purchaseHistoryResponse";
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  IconButton,
  Alert,
} from "@mui/material";
import { VisibilityOutlined, Delete } from "@mui/icons-material";
import { Loading } from "components/Loading";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { ConfirmationDialog } from "./ConfirmationDialog";
import { SnackBarMessage } from "components/SnackBar";
import { usePurchaseHistory } from "hooks/usePurchaseHistory";
import { QrPurchaseHistory } from "./QrPurchaseHistory";
import {
  CAMPO_S,
  ESTADO_COMPRA_AGENDADA,
  ID_ESTADO_COMPRA_AGENDADA,
  ID_ESTADO_COMPRA_POSTULADA,
} from "utils/constants";
import NubeEvento from "assets/images/nube_evento.png";
import NubeEventoVirtual from "assets/images/nube_evento_virtual.svg";

export const PurchaseHistory = () => {
  const { getHistory, deletePurchase } = usePurchaseHistory();

  const [purchaseHistory, setPurchaseHistory] =
    useState<MPurchaseHistory[]>([]);

  const navigate = useNavigate();

  const [idToDelete, setIdToDelete] = useState<number>(0);

  const [isLoading, setIsLoading] = useState(true);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const onGetHistory = async () => {
      setIsLoading(true);

      const purchaseHistory = await getHistory(page, rowsPerPage);

      const findVirtual = purchaseHistory.content.find(
        (virtual) => virtual.virtual === "S"
      );

      setPurchaseHistory(purchaseHistory?.content);

      setTotalPages(purchaseHistory?.totalElements);

      setIsLoading(false);
    };

    onGetHistory();
  }, [page, rowsPerPage]);

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = (idCompra: number) => {
    setIdToDelete(idCompra);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDelete = async (idCompra: number) => {
    const deleted: any = await deletePurchase(idCompra);
    deleted?.status === 200 && setPurchaseHistory(
      purchaseHistory.filter(purchase => purchase.idCompra != idCompra)
    )
    handleCloseDialog();
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="p-8 bg-[#FBFBFB]">
          <div>
            <p className="text-2xl font-bold text-principal-600 pb-4">
              Historial de compras
            </p>
          </div>
          <div className="p-4 border border-principal-350 rounded  shadow">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="font-bold text-principal-600"
                      align="center"
                    >
                      Redimido
                    </TableCell>
                    <TableCell
                      className="font-bold text-principal-600"
                      align="center"
                    >
                      Negocio-Servicio
                    </TableCell>
                    <TableCell
                      className="font-bold text-principal-600"
                      align="center"
                    >
                      Centro-plan
                    </TableCell>
                    <TableCell
                      className="font-bold text-principal-600"
                      align="center"
                    >
                      Fecha del evento
                    </TableCell>
                    <TableCell
                      className="font-bold text-principal-600"
                      align="center"
                    >
                      Estado
                    </TableCell>
                    <TableCell
                      className="font-bold text-principal-600"
                      align="center"
                    >
                      Valor
                    </TableCell>
                    <TableCell
                      className="font-bold text-principal-600"
                      align="center"
                    >
                      Tipo de evento
                    </TableCell>
                    <TableCell
                      className="font-bold text-principal-600"
                      align="center"
                    >
                      Oportunidad
                    </TableCell>
                    <TableCell
                      className="font-bold text-principal-600"
                      align="center"
                    >
                      Ver detalle
                    </TableCell>
                    <TableCell
                      className="font-bold text-principal-600"
                      align="center"
                    >
                      Eliminar
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {purchaseHistory &&
                    purchaseHistory?.map((rowData, index) => (
                      <TableRow
                        key={index}
                        className="border-x-4"
                        style={{ borderColor: rowData.color }}
                      >
                        <TableCell
                          className="text-principal-650"
                          align="center"
                        >
                          {rowData.indUso}
                        </TableCell>
                        <TableCell
                          className="text-principal-650"
                          align="center"
                        >
                          {rowData.nombreNegCat}
                        </TableCell>
                        <TableCell
                          className="text-principal-650"
                          align="center"
                        >
                          {rowData.nombreServCentro}
                        </TableCell>
                        <TableCell
                          className="text-principal-650"
                          align="center"
                        >
                          {moment(rowData.fechaServicio).format("YYYY/MM/DD")}
                        </TableCell>
                        <TableCell
                          align="center"
                          className="font-bold"
                          style={
                            rowData.idEstadoActual === 3 ||
                              rowData.idEstadoActual === 10 ||
                              rowData.valorTotal === 0 ||
                              rowData.indOrigen === "V" ||
                              rowData.indOrigen === "C"
                              ? { color: "green" }
                              : { color: "red" }
                          }
                        >
                          {rowData.nombreEstado}
                        </TableCell>
                        <TableCell
                          className="text-principal-650"
                          align="center"
                        >
                          {(rowData.nombreEstado != ESTADO_COMPRA_AGENDADA ||
                            rowData.idEstadoActual !=
                            ID_ESTADO_COMPRA_AGENDADA) &&
                            rowData.valorTotal.toLocaleString("es-CO", {
                              style: "currency",
                              currency: "COP",
                              minimumFractionDigits: 0,
                            })}
                        </TableCell>
                        <TableCell
                          className="text-principal-650"
                          align="center"
                        >
                          {(rowData.virtual === CAMPO_S && (
                            <div className="flex justify-center">
                              <div
                                className="cursor-pointer "
                                onClick={() =>
                                  navigate(
                                    `/evento-virtual?idCompra=${rowData.idCompra}`
                                  )
                                }
                              >
                                <img
                                  src={NubeEventoVirtual}
                                  className="h-15 w-15 "
                                />
                                <p className="w-full text-principal-250 font-bold">
                                  Ir al
                                </p>
                                <p className="w-full text-principal-250 font-bold">
                                  evento
                                </p>
                              </div>
                            </div>
                          )) || (
                              <>
                                {(rowData.nombreEstado !=
                                  ESTADO_COMPRA_AGENDADA ||
                                  rowData.idEstadoActual !=
                                  ID_ESTADO_COMPRA_AGENDADA) && !(rowData.idEstadoActual === 8 || rowData.idEstadoActual === ID_ESTADO_COMPRA_POSTULADA) && (
                                    <QrPurchaseHistory
                                      idCompra={rowData.idCompra}
                                      color={rowData.color}
                                    ></QrPurchaseHistory>
                                  )}
                              </>
                            )}
                        </TableCell>
                        <TableCell
                          className="text-principal-650"
                          align="center"
                        >
                          {rowData.idOportunidad}
                        </TableCell>
                        <TableCell align="center">
                          {/* Personalizar la celda de ver detalle aquí */}
                          <IconButton
                            aria-label="Visualizar"
                            component={Link}
                            to={`/detalle-servicio/${rowData.idServicio}/${rowData.idCompra}`}
                          >
                            <VisibilityOutlined />
                          </IconButton>
                        </TableCell>
                        <TableCell align="center">
                          {/* Personalizar la celda de eliminar aquí */}
                          {
                            rowData.idEstadoActual !== ID_ESTADO_COMPRA_POSTULADA && (
                              <IconButton
                                aria-label="Eliminar"
                                onClick={() => handleOpenDialog(rowData.idCompra)}
                              >
                                <Delete />
                              </IconButton>)
                          }

                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalPages}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Filas por página:"
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${totalPages}`
              }
            />
          </div>
        </div>
      )}

      <ConfirmationDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onConfirm={() => handleDelete(idToDelete)}
      />
    </>
  );
};
